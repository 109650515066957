/**
 * MinersFactory | 404 error page
 */

import React from "react"
import { Link } from "gatsby"

import SubPage from "./../templates/subpage"
import SEO from "./../components/seo"

const NotFoundPage = () => (
    <SubPage>
        <SEO title="Fehler 404 - Seite nicht gefunden" />
        <div className="container">
            <section>
                <h1>
                    <b>
                        Fehler 404 - Seite nicht gefunden{" "}
                        <span role="img" aria-label="Hmm!">
                            🧐
                        </span>
                    </b>
                </h1>
                <p>
                    Hoppla! Die aufgerufene Seite existiert leider nicht (mehr).
                </p>
                <p>
                    Aber kein Problem! Auf der <Link to="/">Startseite</Link>{" "}
                    findest du alle notwendigen Infos.
                </p>
            </section>
        </div>
    </SubPage>
)

export default NotFoundPage
